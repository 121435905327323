.scrolldown1 {
  height: 90px;
  position: absolute;
  bottom: -30px;
  left: 40%;
}

.scrolldown1::after {
  content: "";
  position: absolute;
  top: 0;
  width: 1px;
  height: 60px;
  background: #eee;
  animation: pathmove 1.4s ease-in-out infinite;
  opacity: 0;
}

@keyframes pathmove {
  0% {
    height: 0;
    top: 0;
    opacity: 0;
  }
  30% {
    height: 40px;
    opacity: 1;
  }
  50% {
    height: 50px;
    opacity: 1;
  }
  100% {
    height: 30;
    top: 50px;
    opacity: 0;
  }
}
