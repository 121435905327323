@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Proxima+Nova");

@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot");
  src: url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff")
      format("woff"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf")
      format("truetype"),
    url("https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

.css-137t2g6-MuiPaper-root-MuiAppBar-root,
.css-105hrg4-MuiPaper-root-MuiAppBar-root,
.css-cng96e-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}
